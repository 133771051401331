<template>
    <el-form :model="selfInfo" :rules="rules" ref="ruleForm">
        <!-- 个人信息 -->
        <div class="selfInfo">
            <div class="handle">
                <div style="margin-top:10px;font-size:16px;font-weight:bold">
                    个人信息
                </div>
                <div style="color: orange; margin-top: 10px;font-size:12px">
                    提示：个人信息需如实填写
                </div>
            </div>
        </div>
        <div class="selfInfoBox">
            <el-row>
                <el-col :span="14">
                    <!--姓名&&性别-->
                    <el-row class="name">
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span class="must">*</span>
                                <span>姓名</span>
                            </p>
                            <el-form-item prop="name">
                                <el-input
                                        :maxlength="30"
                                        ref="name"
                                        v-model="selfInfo.name"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span class="must">*</span>
                                <span>性别</span>
                            </p>
                            <el-radio-group @change="changeGender" v-model="sex">
                                <el-radio :label="0">男</el-radio>
                                <el-radio :label="1">女</el-radio>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <!--证件号码&&入学年份-->
                    <el-row class="commBox">
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span class="must">*</span>
                                <span>证件号码</span>
                            </p>
                            <el-input
                                    :disabled="true"
                                    :maxlength="18"
                                    v-model="sfzNum"
                            ></el-input>
                        </el-col>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span class="must">*</span>
                                <span>入学年份(高中)</span>
                            </p>
                            <el-form-item prop="start_school_date">
                                <el-select
                                        :popper-append-to-body="false"
                                        placeholder="请选择入学年份（高中）"
                                        ref="start_school_date"
                                        v-model="selfInfo.start_school_date"
                                >
                                    <el-option
                                            :key="index"
                                            :label="item + '年'"
                                            :value="item"
                                            v-for="(item, index) in rxYearArrs"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!--出生日期-->
                    <el-row class="bd-con">
                        <el-col :span="8">
                            <p class="form-room-box">
                                <span>出生日期</span>
                            </p>
                            <el-select :popper-append-to-body="false" placeholder="年"
                                       v-model="bdYear">
                                <el-option
                                        :key="index"
                                        :label="item"
                                        :value="item"
                                        v-for="(item, index) in yearArrs"
                                >
                                </el-option>
                            </el-select>
                        </el-col>

                        <el-col :span="8">
                            <p class="form-room-box"></p>
                            <el-select :popper-append-to-body="false" placeholder="月"
                                       v-model="bdMonth">
                                <el-option
                                        :key="index"
                                        :label="item"
                                        :value="item"
                                        v-for="(item, index) in monthArrs"
                                >
                                </el-option>
                            </el-select>
                        </el-col>

                        <el-col :span="8">
                            <p class="form-room-box"></p>
                            <el-select :popper-append-to-body="false" placeholder="日"
                                       v-model="bdDay">
                                <el-option
                                        :key="index"
                                        :label="item"
                                        :value="item"
                                        v-for="(item, index) in dayArrs"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <!--籍贯-->
                    <el-row>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span>籍贯</span>
                            </p>
                            <el-select
                                    :popper-append-to-body="false"
                                    @change="selectJgProvince($event, '0')"
                                    placeholder="省份"
                                    v-model="jgProvinceCode"
                            >
                                <el-option
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                        v-for="item in provinceData"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="12">
                            <p class="form-room-box"></p>
                            <el-select :popper-append-to-body="false" placeholder="城市"
                                       v-model="jgCityCode">
                                <el-option
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                        v-for="item in currentJgCityList"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <!-- 生源地 -->
                    <el-row>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span>生源地</span>
                            </p>
                            <el-select
                                    :popper-append-to-body="false"
                                    @change="selectSydProvince"
                                    placeholder="省份"
                                    v-model="sydProvinceCode"
                            >
                                <el-option
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                        v-for="item in provinceData"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="12">
                            <p class="form-room-box"></p>
                            <el-select :popper-append-to-body="false" placeholder="城市"
                                       v-model="sydCityCode">
                                <el-option
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                        v-for="item in currentSydCityList"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <!-- 民族、政治面貌 -->
                    <el-row>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span>民族</span>
                            </p>
                            <el-form-item>
                                <el-input :maxlength="18" v-model="selfInfo.nation"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span>政治面貌</span>
                            </p>
                            <el-form-item>
                                <el-input
                                        :maxlength="18"
                                        v-model="selfInfo.political_status"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 科类、外语 -->
                    <el-row class="commBox">
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span>科类</span>
                            </p>
                            <el-select
                                    :popper-append-to-body="false"
                                    @change="selectSubjectInfo"
                                    placeholder="科类"
                                    v-model="selfInfo.category"
                            >
                                <el-option
                                        :key="index"
                                        :label="item"
                                        :value="index + 1"
                                        v-for="(item, index) in majorList"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span>外语语种</span>
                            </p>
                            <el-input
                                    :maxLength="18"
                                    v-model="selfInfo.foreign_language"
                            ></el-input>
                        </el-col>
                    </el-row>
                    <!--  选科科目 -->
                    <el-row v-if="subjectStatus">
                        <div style="color: orange; margin-top: 10px">
                            此处选考科对考试科目没有影响，考试软件支持9个科目开考
                        </div>
                        <el-form-item label="选择科目">
                            <el-checkbox-group :max="3" :min="1" v-model="checkedSubject">
                                <el-checkbox
                                        :key="item.id"
                                        :label="item.id"
                                        ref="checkedSubject"
                                        v-for="item in subjectsList"
                                >{{ item.name }}
                                </el-checkbox
                                >
                            </el-checkbox-group>
                        </el-form-item>
                    </el-row>
                    <!-- 手机号码、邮箱 -->
                    <el-row>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span class="must">*</span>
                                <span>手机号码</span>
                            </p>
                            <el-form-item prop="phone">
                                <el-input
                                        :maxLength="11"
                                        ref="phone"
                                        v-model="selfInfo.phone"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span class="must">*</span>
                                <span>邮箱</span>
                            </p>
                            <el-form-item prop="email">
                                <el-input
                                        :maxLength="50"
                                        ref="email"
                                        v-model="selfInfo.email"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 家庭住址 -->
                    <el-row class="commBox">
                        <el-col :span="12">
                            <p class="form-room-box">
                                <span>家庭住址</span>
                            </p>
                            <el-select
                                    :popper-append-to-body="false"
                                    @change="selectJtProvince"
                                    placeholder="省份"
                                    v-model="jtProvinceCode"
                            >
                                <el-option
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                        v-for="item in provinceData"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="12">
                            <p class="form-room-box"></p>
                            <el-select :popper-append-to-body="false" placeholder="城市"
                                       v-model="jtCityCode">
                                <el-option
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                        v-for="item in currentJtCityList"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row class="jtzz-con">
                        <el-col :span="24">
                            <el-form-item>
                                <el-input
                                        :maxLength="50"
                                        placeholder="请填写家庭详细地址"
                                        style="width: 90%"
                                        v-model="selfInfo.address"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="10">
                    <!-- 证件照区 -->
                    <div class="pictureRoom">
                        <div class="picture">
                            <el-upload
                                    :before-upload="beforeAvatarUpload"
                                    :http-request="uploadPicture"
                                    :show-file-list="false"
                                    accept=".png, .jpg"
                                    action="#"
                            >
                                <div class="upload_img_box">
                                    <div class="upload_sign">证件照</div>
                                    <div class="upload_img">
                                        <img
                                                :src="selfInfo.picture_path"
                                                style="width: 100%; height: 100%"
                                                v-if="selfInfo.picture_path"
                                        />
                                        <img
                                                src="../../src/assets/images/defaultAvatar.png"
                                                style="width: 100%; height: 100%"
                                                v-else
                                        />
                                    </div>
                                </div>
                                <!-- 上传图片按钮文字区 -->
                                <div class="upload_text_box">
                                    <div class="upload_text_item1">上传证件照</div>
                                    <el-button
                                            size="small"
                                            style="
                      margin-bottom: 20px;
                      background: #563279;
                      color:#fff;
                      border: none;
                      width: 120px;
                    "
                                            type="primary"
                                    >点击上传
                                    </el-button
                                    >
                                    <div class="uload_text_item2" slot="tip">
                                        <!-- <div>1、上传2寸证件照，最大尺寸为137*208像素</div> -->
                                        <div>1、允许的文件类型为 JPG/JPEG/PNG</div>
                                        <div>2、文件大小不超过 2M</div>
                                    </div>
                                </div>
                            </el-upload>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="formRoom"></div>
        </div>
        <!-- 家庭信息 -->
        <div class="familyInfo">
            <div class="handle">
                <div style="font-size:16px;font-weight:bold">家庭信息</div>
            </div>
            <div
                    :key="index"
                    class="family-item"
                    v-for="(item, index) in selfInfo.familyinfo"
            >
                <div style="font-weight: bold">成员{{ index + 1 }}</div>
                <div class="item-box">
                    <div class="items">
                        <div class="items-handle">成员姓名</div>
                        <el-input :maxLength="32" v-model="item.name"></el-input>
                    </div>
                    <div class="items">
                        <div class="items-handle">关系</div>
                        <el-input :maxLength="18" v-model="item.relation"></el-input>
                    </div>
                </div>

                <div class="item-box">
                    <div class="items">
                        <div class="items-handle">职业</div>
                        <el-input :maxLength="28" v-model="item.career"></el-input>
                    </div>
                    <div class="items">
                        <div class="items-handle">工作单位</div>
                        <el-input :maxLength="50" v-model="item.workplace"></el-input>
                    </div>
                </div>
                <div class="item-box">
                    <div class="items">
                        <div class="items-handle">职务</div>
                        <el-input :maxLength="18" v-model="item.job"></el-input>
                    </div>
                    <div class="items">
                        <div class="items-handle">文化程度</div>
                        <el-input :maxLength="18" v-model="item.educationdegree"></el-input>
                    </div>
                </div>

                <div class="item-box">
                    <div class="items">
                        <div class="items-handle">联系电话</div>
                        <el-input :maxLength="11" v-model="item.phone"></el-input>
                    </div>
                </div>
            </div>
        </div>
        <!-- 学校信息 -->
        <div class="schoolinfo">
            <div class="handle">学校信息</div>
            <div class="schoolName">
                <div class="handle">
                    <span class="must">*</span>
                    <span>就读中学</span>
                </div>
                <div class="box">
                    <el-form-item prop="schoolprovince">
                        <el-select
                                :popper-append-to-body="false"
                                @change="selectSchoolProvince"
                                filterable
                                placeholder="省份"
                                ref="schoolprovince"
                                v-model="selfInfo.schoolprovince"
                        >
                            <el-option
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                    v-for="item in provinceData"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="center" prop="schoolcity">
                        <el-select
                                :popper-append-to-body="false"
                                @change="selectSchoolCity"
                                filterable
                                placeholder="城市"
                                ref="schoolcity"
                                v-model="selfInfo.schoolcity"
                        >
                            <el-option
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                    v-for="item in currentSchoolCityList"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="school">
                        <el-select
                                :popper-append-to-body="false"
                                @change="selectSchool"
                                filterable
                                placeholder="中学名称"
                                ref="school"
                                v-model="selfInfo.school"
                        >
                            <el-option
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                    v-for="item in currentSchoolList"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="handle">中学地址</div>
                <el-input
                        :maxLength="50"
                        @input="onInput()"
                        class="schooladdr"
                        v-model="selfInfo.schoolinfo.address"
                ></el-input>
                <div class="box">
          <span>
            <div class="handle">中学邮编</div>
            <el-input
                    @input="onInput()"
                    v-model="selfInfo.schoolinfo.postcode"
            ></el-input>
          </span>
                    <span class="center">
            <div class="handle">中学联系人</div>
            <el-input
                    @input="onInput()"
                    v-model="selfInfo.schoolinfo.contactpeople"
            ></el-input>
          </span>
                    <span>
            <div class="handle">联系电话</div>
            <el-input
                    @input="onInput()"
                    v-model="selfInfo.schoolinfo.contactphone"
            ></el-input>
          </span>
                </div>
                <div class="box">
          <span>
            <div class="handle">教务处电话</div>
            <el-input
                    @input="onInput()"
                    v-model="selfInfo.schoolinfo.jdcphone"
            ></el-input>
          </span>
                </div>
            </div>
        </div>
        <!-- 学习成绩 -->
        <div class="schoolRecord">
            <div class="handle">学习成绩</div>
            <div class="recordTable">
                <table border="0" cellspacing="0">
                    <tr>
                        <th></th>
                        <th>
                            <div>语文</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>数学</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>英语</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>物理</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>化学</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>生物</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>政治</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>历史</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>地理</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>文理(综合)</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>年级排名</div>
                            <div>(名次/总人数)</div>
                        </th>
                    </tr>
                    <template v-for="(items, indexs) in selfInfo.scoreinfo">
                        <tr :key="indexs" v-if="items.gradeIndex != 5">
                            <td>{{ items.grade }}</td>
                            <td :key="index" v-for="(item, index) in items.scoreItems">
                                <template v-if="item.subjectIndex == 10">
                                    <input
                                            class="gradint"
                                            oninput="if(value=='00'){value='0';}else{value=value.replace(/[^0-9]/g,'');if(value !=''){value = parseInt(value)}};"
                                            v-model="item.myscore"
                                    />
                                    <span>/</span>
                                    <input
                                            class="gradint"
                                            oninput="if(value=='00'){value='0';}else{value=value.replace(/[^0-9]/g,'');if(value !=''){value = parseInt(value)}};"
                                            v-model="item.totalscore"
                                    />
                                </template>
                                <template v-else>
                                    <input
                                            @blur="onBlurValue(indexs, index, '0')"
                                            @input="onInputValue(indexs, index, '0')"
                                            class="gradint"
                                            oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="item.myscore"
                                    />
                                    <span>/</span>
                                    <input
                                            @blur="onBlurValue(indexs, index, '1')"
                                            @input="onInputValue(indexs, index, '1')"
                                            class="gradint"
                                            oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="item.totalscore"
                                    />
                                </template>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
            <div class="handle">最后一次大考成绩</div>
            <div class="recordTable">
                <table border="0" cellspacing="0">
                    <tr>
                        <th></th>
                        <th>
                            <div>语文</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>数学</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>英语</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>物理</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>化学</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>生物</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>政治</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>历史</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>地理</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>文理(综合)</div>
                            <div>(得分/总分)</div>
                        </th>
                        <th>
                            <div>年级排名</div>
                            <div>(名次/总人数)</div>
                        </th>
                    </tr>
                    <tr>
                        <td>最后大考</td>
                        <td
                                :key="'scoreinfo-' + index"
                                v-for="(item, index) in selfInfo.scoreinfo[5].scoreItems"
                        >
                            <template v-if="item.subjectIndex == 10">
                                <input
                                        class="gradint"
                                        oninput="if(value=='00'){value='0';}else{value=value.replace(/[^0-9]/g,'');if(value !=''){value = parseInt(value)}};"
                                        v-model="item.myscore"
                                />
                                <span>/</span>
                                <input
                                        class="gradint"
                                        oninput="if(value=='00'){value='0';}else{value=value.replace(/[^0-9]/g,'');if(value !=''){value = parseInt(value)}};"
                                        v-model="item.totalscore"
                                />
                            </template>
                            <template v-else>
                                <input
                                        @blur="onBlurValue(5, index, '0')"
                                        @input="onInputValue(5, index, '0')"
                                        class="gradint"
                                        oninput="value=value.replace(/[^\d.]/g,'')"
                                        v-model="item.myscore"
                                />
                                <span>/</span>
                                <input
                                        @blur="onBlurValue(5, index, '1')"
                                        @input="onInputValue(5, index, '1')"
                                        class="gradint"
                                        oninput="value=value.replace(/[^\d.]/g,'')"
                                        v-model="item.totalscore"
                                />
                            </template>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!-- 志愿情况 -->
        <div class="schoolRecord">
            <!-- 志愿情况 -->
            <div class="wash">
                <div class="handle">志愿情况</div>
                <!--                <div class="handle2">强基计划志愿</div>-->
                <!--                <div-->
                <!--                        class="wash-item-box"-->
                <!--                        v-for="(item, index) in selfInfo.qjwishinfo"-->
                <!--                        :key="'qjwishinfo-' + index"-->
                <!--                >-->
                <!--                    <div class="hand">第{{ smallToBig(index) }}志愿</div>-->
                <!--                    <el-row>-->
                <!--                        <el-col :span="9">-->
                <!--                            <p class="form-room-box">-->
                <!--                                <span>学校名称</span>-->
                <!--                            </p>-->
                <!--                            <el-select-->
                <!--                                    v-model="qjWishList[index].zyunivid"-->
                <!--                                    placeholder="请选择"-->
                <!--                                    :popper-append-to-body="false"-->
                <!--                                    @change="selectUniversity($event, 1, index)"-->
                <!--                            >-->
                <!--                                <el-option-->
                <!--                                        v-for="item in universityList"-->
                <!--                                        :key="item.id"-->
                <!--                                        :label="item.name"-->
                <!--                                        :value="Number(item.id)"-->
                <!--                                >-->
                <!--                                </el-option>-->
                <!--                            </el-select>-->
                <!--                        </el-col>-->
                <!--                        <el-col :span="15">-->
                <!--                            <p class="form-room-box">-->
                <!--                                <span>报名专业</span>-->
                <!--                            </p>-->
                <!--                            <el-row :gutter="20">-->
                <!--                                <el-col class="apply_input" :span="8">-->
                <!--                                    <el-select-->
                <!--                                            v-model="qjWishList[index].zy1id"-->
                <!--                                            placeholder="请选择"-->
                <!--                                            :popper-append-to-body="false"-->
                <!--                                            @change="selectWish($event, 1, index, 1)"-->
                <!--                                    >-->
                <!--                                        <el-option-->
                <!--                                                v-for="item in professionList"-->
                <!--                                                :key="item.id"-->
                <!--                                                :label="item.name"-->
                <!--                                                :value="Number(item.id)"-->
                <!--                                        >-->
                <!--                                        </el-option>-->
                <!--                                    </el-select>-->
                <!--                                </el-col>-->
                <!--                                <el-col class="apply_input" :span="8">-->
                <!--                                    <el-select-->
                <!--                                            v-model="qjWishList[index].zy2id"-->
                <!--                                            placeholder="请选择"-->
                <!--                                            :popper-append-to-body="false"-->
                <!--                                            @change="selectWish($event, 1, index, 2)"-->
                <!--                                    >-->
                <!--                                        <el-option-->
                <!--                                                v-for="item in qjWishList[index].qjZYListTwo"-->
                <!--                                                :key="item.id"-->
                <!--                                                :label="item.name"-->
                <!--                                                :value="Number(item.id)"-->
                <!--                                        >-->
                <!--                                        </el-option>-->
                <!--                                    </el-select>-->
                <!--                                </el-col>-->
                <!--                                <el-col class="apply_input" :span="8">-->
                <!--                                    <el-select-->
                <!--                                            v-model="qjWishList[index].zy3id"-->
                <!--                                            placeholder="请选择"-->
                <!--                                            :popper-append-to-body="false"-->
                <!--                                            @change="selectWish($event, 1, index, 3)"-->
                <!--                                    >-->
                <!--                                        <el-option-->
                <!--                                                v-for="item in qjWishList[index].qjZYListThree"-->
                <!--                                                :key="item.id"-->
                <!--                                                :label="item.name"-->
                <!--                                                :value="Number(item.id)"-->
                <!--                                        >-->
                <!--                                        </el-option>-->
                <!--                                    </el-select>-->
                <!--                                </el-col>-->
                <!--                            </el-row>-->
                <!--                        </el-col>-->
                <!--                    </el-row>-->
                <!--                </div>-->

                <div class="handle2">国内高校志愿</div>
                <div
                        :key="'wishinfo-' + index"
                        class="wash-item-box"
                        v-for="(item, index) in selfInfo.wishinfo"
                >
                    <div class="hand">第{{ smallToBig(index) }}志愿</div>
                    <el-row>
                        <el-col :span="9">
                            <p class="form-room-box">
                                <span>学校名称</span>
                            </p>
                            <el-select
                                    :popper-append-to-body="false"
                                    @change="selectUniversity($event, 2, index)"
                                    placeholder="请选择"
                                    v-model="wishList[index].zyunivid"
                            >
                                <el-option
                                        :key="item.id"
                                        :label="item.name"
                                        :value="Number(item.id)"
                                        v-for="item in universityList"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="15">
                            <p class="form-room-box">
                                <span>报名专业</span>
                            </p>
                            <el-row :gutter="20">
                                <el-col :span="8" class="apply_input">
                                    <el-select
                                            :popper-append-to-body="false"
                                            @change="selectWish($event, 2, index, 1)"
                                            placeholder="请选择"
                                            v-model="wishList[index].zy1id"
                                    >
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="Number(item.id)"
                                                v-for="item in professionList"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="8" class="apply_input">
                                    <el-select
                                            :popper-append-to-body="false"
                                            @change="selectWish($event, 2, index, 2)"
                                            placeholder="请选择"
                                            v-model="wishList[index].zy2id"
                                    >
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="Number(item.id)"
                                                v-for="item in wishList[index].wishListTwo"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <!--                                <el-col class="apply_input" :span="8">-->
                                <!--                                    <el-select-->
                                <!--                                            v-model="wishList[index].zy3id"-->
                                <!--                                            placeholder="请选择"-->
                                <!--                                            :popper-append-to-body="false"-->
                                <!--                                            @change="selectWish($event, 2, index, 3)"-->
                                <!--                                    >-->
                                <!--                                        <el-option-->
                                <!--                                                v-for="item in wishList[index].wishListThree"-->
                                <!--                                                :key="item.id"-->
                                <!--                                                :label="item.name"-->
                                <!--                                                :value="Number(item.id)"-->
                                <!--                                        >-->
                                <!--                                        </el-option>-->
                                <!--                                    </el-select>-->
                                <!--                                </el-col>-->
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
                <div class="addWishBtn">
                    <p @click="addWish" v-if="!hideAddWishBtn">添加志愿</p>
                </div>
                <div class="handle2">中外合作办学高校志愿</div>
                <div
                        :key="'coopwishinfo-' + index"
                        class="wash-item-box"
                        v-for="(item, index) in selfInfo.coopwishinfo"
                >
                    <div class="hand">第{{ smallToBig(index) }}志愿</div>
                    <el-row>
                        <el-col :span="9">
                            <p class="form-room-box">
                                <span>学校名称</span>
                            </p>
                            <el-select
                                    :popper-append-to-body="false"
                                    @change="selectUniversity($event, 3, index)"
                                    placeholder="请选择"
                                    v-model="coopWishList[index].zyunivid"
                            >
                                <el-option
                                        :key="item.id"
                                        :label="item.name"
                                        :value="Number(item.id)"
                                        v-for="item in universityOutList"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="15">
                            <p class="form-room-box">
                                <span>报名专业</span>
                            </p>
                            <el-row :gutter="20">
                                <el-col :span="8" class="apply_input">
                                    <el-select
                                            :popper-append-to-body="false"
                                            @change="selectWish($event, 3, index, 1)"
                                            placeholder="请选择"
                                            v-model="coopWishList[index].zy1id"
                                    >
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="Number(item.id)"
                                                v-for="item in coopWishList[index].coopWishListOne"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="8" class="apply_input">
                                    <el-select
                                            :popper-append-to-body="false"
                                            @change="selectWish($event, 3, index, 2)"
                                            placeholder="请选择"
                                            v-model="coopWishList[index].zy2id"
                                    >
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="Number(item.id)"
                                                v-for="item in coopWishList[index].coopWishListTwo"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <!-- 授权说明模块 -->
        <div class="share" id="share">
            <el-form-item>
                <div class="flex auth_choose">
                    <div><span>*</span>你是否同意将考试相关数据共享至你所选择的志愿高校：</div>
                    <el-radio-group v-model="selfInfo.share">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>

                </div>
                <div class="auth_tips">
                    说明：
                    <div>
                        1、如同意共享，THUSSAT项目组有可能将你的考试相关数据共享至你所填写的志愿高校。共享的数据包括完整报名信息表数据和考试成绩及排名数据。
                    </div>
                    <div>
                        2、如所选择的志愿高校多于1个，共享给每个高校的数据均不包含其余高校的志愿信息。
                    </div>
                </div>
            </el-form-item>

        </div>
        <!-- 防误触弹框 -->
        <dialog-box
                :dialogFormFu="dialogFormFu"
                :dialogVisibleFu="dialogVisibleFu"
                @closeDialog="closeDialog"
        ></dialog-box>
    </el-form>
</template>

<script>
    import COS from "cos-js-sdk-v5";
    import validator from "common/validator";
    import {
        getAccount,
        getCityList,
        getProfession,
        getProvinceList,
        getSchoolList,
        getSubjects,
        getUniversity,
    } from "r/index/signUp";
    import {getAvatarConfig, getAvatarConfigCos} from "r/index/identity";
    import {upload} from "r/request";
    import {nameCheck,} from "common/utils.js";
    import DialogBox from "c/index/register/DialogBox";

    export default {
        name: "selfInfo",
        props: {
            selfInfos: {
                type: Object,
                default: {
                    //个人信息
                    name: "", //学生姓名
                    phone: "", //手机号
                    sex: 0, //性别 0男  1女
                    email: "", //邮箱
                    jiguan_code: undefined, //籍贯 籍贯code 【省份id+城市id】
                    shengyuandi_code: undefined, //生源code 【省份id+城市id】
                    birthday: "", //出生日期，类似20020624
                    nation: "", //民族
                    political_status: "", //政治面貌
                    start_school_date: "", //入学年份
                    category: "", //科类[文理科 1 文科 2 理科 3 选科]
                    gk_selectsubject: "", //选科科目【格式：3001#3002#3008】
                    foreign_language: "", //语种
                    address_code: "", //家庭住址省市code【省份id+城市id】
                    address: "", //家庭住址
                    familyinfo: [
                        //家庭信息
                        {
                            career: "",
                            educationdegree: "",
                            job: "",
                            name: "",
                            phone: "",
                            relation: "",
                            workplace: "",
                        },
                        {
                            career: "",
                            educationdegree: "",
                            job: "",
                            name: "",
                            phone: "",
                            relation: "",
                            workplace: "",
                        },
                    ],
                    schoolprovince: "", //学校省份code（id）通过【获取所有省份列表】获得
                    schoolcity: "", //学校城市code（id）通过【获取所有城市列表】获得
                    school: "", //学校code（id）通过【获取所有学校列表】获得
                    schoolinfo: {
                        //学校信息
                        contactpeople: "",
                        contactphone: "",
                        address: "",
                        jdcphone: "",
                        postcode: "",
                        province: "",
                        city: "",
                        name: "",
                    },
                    scoreinfo: [
                        //成绩信息
                        {
                            grade: "高一上(期末)",
                            gradeIndex: 0,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高一下(期末)",
                            gradeIndex: 1,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高二上(期末)",
                            gradeIndex: 2,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高二下(期末)",
                            gradeIndex: 3,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高三上(期末)",
                            gradeIndex: 4,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "最后大考",
                            gradeIndex: 5,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                    ],
                    wishinfo: [
                        //国内志愿
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                    ],
                    coopwishinfo: [
                        //国外志愿
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                        },
                    ],
                    qjwishinfo: [
                        //强基计划志愿
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                    ],
                },
            },
        },
        data() {
            return {
                reserveErrorDialogFu: false,
                // 提示弹框文字
                dialogFormFu: {
                    title: "",
                    message: "",
                },
                dialogVisibleFu: false, // 控制提示弹框的显示与隐藏
                sex: 0,
                // username: "", //用户名，本地存储获取
                sfzNum: "", //证件号码，本地存储取
                selfInfo: {
                    //个人信息
                    name: "", //学生姓名
                    phone: "", //手机号
                    sex: 0, //性别 0男  1女
                    email: "", //邮箱

                    jiguan_code: undefined, //籍贯 籍贯code 【省份id+城市id】
                    shengyuandi_code: undefined, //生源code 【省份id+城市id】
                    birthday: "", //出生日期，类似20020624
                    nation: "", //民族
                    political_status: "", //政治面貌
                    start_school_date: "", //入学年份
                    category: "", //科类[文理科 1 文科 2 理科 3 选科]
                    gk_selectsubject: "", //选科科目【格式：3001#3002#3008】
                    foreign_language: "", //语种
                    address_code: "", //家庭住址省市code【省份id+城市id】
                    address: "", //家庭住址
                    familyinfo: [
                        //家庭信息
                        {
                            career: "",
                            educationdegree: "",
                            job: "",
                            name: "",
                            phone: "",
                            relation: "",
                            workplace: "",
                        },
                        {
                            career: "",
                            educationdegree: "",
                            job: "",
                            name: "",
                            phone: "",
                            relation: "",
                            workplace: "",
                        },
                    ],
                    schoolprovince: "", //学校省份code（id）通过【获取所有省份列表】获得
                    schoolcity: "", //学校城市code（id）通过【获取所有城市列表】获得
                    school: "", //学校code（id）通过【获取所有学校列表】获得
                    schoolinfo: {
                        //学校信息
                        contactpeople: "",
                        contactphone: "",
                        address: "",
                        jdcphone: "",
                        postcode: "",
                        province: "",
                        city: "",
                        name: "",
                    },
                    scoreinfo: [
                        //成绩信息
                        {
                            grade: "高一上(期末)",
                            gradeIndex: 0,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高一下(期末)",
                            gradeIndex: 1,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高二上(期末)",
                            gradeIndex: 2,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高二下(期末)",
                            gradeIndex: 3,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高三上(期末)",
                            gradeIndex: 4,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "最后大考",
                            gradeIndex: 5,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                    ],
                    wishinfo: [
                        //国内志愿
                        {
                            zyunivid: "",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                    ],
                    coopwishinfo: [
                        //国外志愿
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                        },
                    ],
                    qjwishinfo: [
                        //强基计划志愿
                        {
                            zyunivid: "",
                            zy1id: "",
                            zy2id: "",
                            zy3id: "",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "",
                            zy1id: "",
                            zy2id: "",
                            zy3id: "",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "",
                            zy1id: "",
                            zy2id: "",
                            zy3id: "",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                    ],
                },
                selfInfoOld: {
                    //个人信息
                    name: "", //学生姓名
                    phone: "", //手机号
                    sex: 0, //性别 0男  1女
                    email: "", //邮箱

                    jiguan_code: undefined, //籍贯 籍贯code 【省份id+城市id】
                    shengyuandi_code: undefined, //生源code 【省份id+城市id】
                    birthday: "", //出生日期，类似20020624
                    nation: "", //民族
                    political_status: "", //政治面貌
                    start_school_date: "", //入学年份
                    category: "", //科类[文理科 1 文科 2 理科 3 选科]
                    gk_selectsubject: "", //选科科目【格式：3001#3002#3008】
                    foreign_language: "", //语种
                    address_code: "", //家庭住址省市code【省份id+城市id】
                    address: "", //家庭住址
                    familyinfo: [
                        //家庭信息
                        {
                            career: "",
                            educationdegree: "",
                            job: "",
                            name: "",
                            phone: "",
                            relation: "",
                            workplace: "",
                        },
                        {
                            career: "",
                            educationdegree: "",
                            job: "",
                            name: "",
                            phone: "",
                            relation: "",
                            workplace: "",
                        },
                    ],
                    schoolprovince: "", //学校省份code（id）通过【获取所有省份列表】获得
                    schoolcity: "", //学校城市code（id）通过【获取所有城市列表】获得
                    school: "", //学校code（id）通过【获取所有学校列表】获得
                    schoolinfo: {
                        //学校信息
                        contactpeople: "",
                        contactphone: "",
                        address: "",
                        jdcphone: "",
                        postcode: "",
                        province: "",
                        city: "",
                        name: "",
                    },
                    scoreinfo: [
                        //成绩信息
                        {
                            grade: "高一上(期末)",
                            gradeIndex: 0,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高一下(期末)",
                            gradeIndex: 1,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高二上(期末)",
                            gradeIndex: 2,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高二下(期末)",
                            gradeIndex: 3,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高三上(期末)",
                            gradeIndex: 4,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "最后大考",
                            gradeIndex: 5,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                    ],
                    wishinfo: [
                        //国内志愿
                        {
                            zyunivid: "",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                    ],
                    coopwishinfo: [
                        //国外志愿
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                        },
                    ],
                    qjwishinfo: [
                        //强基计划志愿
                        {
                            zyunivid: "",
                            zy1id: "",
                            zy2id: "",
                            zy3id: "",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "",
                            zy1id: "",
                            zy2id: "",
                            zy3id: "",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "",
                            zy1id: "",
                            zy2id: "",
                            zy3id: "",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                    ],
                },
                qjWishList: [
                    //强基计划大学志愿列表数据
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        qjZYListTwo: [],
                        qjZYListThree: [],
                    },
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        qjZYListTwo: [],
                        qjZYListThree: [],
                    },
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        qjZYListTwo: [],
                        qjZYListThree: [],
                    },
                ],
                wishList: [
                    //国内大学志愿列表数据
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        wishListTwo: [],
                        wishListThree: [],
                    },
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        wishListTwo: [],
                        wishListThree: [],
                    },
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        wishListTwo: [],
                        wishListThree: [],
                    },
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        wishListTwo: [],
                        wishListThree: [],
                    },
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        wishListTwo: [],
                        wishListThree: [],
                    },
                ],
                coopWishList: [
                    //国外大学志愿列表数据
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        coopWishListOne: [],
                        coopWishListTwo: [],
                    },
                    {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        coopWishListOne: [],
                        coopWishListTwo: [],
                    },
                ],
                yearArrs: [], //生日涉及的近10年的年份
                monthArrs: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                ],
                dayArrs: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                ],
                bdYear: "", //选择的生日年份
                bdMonth: "", //选择的生日月份
                bdDay: "", //选择的生日日期

                provinceData: {}, //接口获取的全国省份数据
                cityData: {}, //接口获取的全国城市数据

                currentJgCityList: [], //当前籍贯省份下的所有城市列表
                jgProvinceCode: "", //籍贯选择的省份
                jgCityCode: "", //籍贯选择的城市
                currentSydCityList: [], //当前生源地省份下的所有城市列表
                sydProvinceCode: "", //生源地选择的省份
                sydCityCode: "", //生源地选择的城市

                rxYearArrs: [], //高中入学年份近11年
                majorList: ["文科", "理科", "选科"], // 科类
                subjectsList: [], //获取学科列表--选科使用
                subjectStatus: false, //控制为选科时内容展示状态
                checkedSubject: [], //选中的科目

                jtProvinceCode: "", //家庭地址选择的省份
                jtCityCode: "", //家庭地址选择的城市
                currentJtCityList: [], //当前籍贯省份下的所有城市列表

                currentSchoolCityList: [], //当前学校省份下的所有城市列表
                currentSchoolList: [], //当前城市下的所有学校列表

                universityList: [], //获取的所有国内学校列表
                universityOutList: [], //获取的所有国外学校列表

                professionList: [], //获取国内顶级大学专业
                professionOutList: [], //获取国外顶级大学专业
                hideAddWishBtn: false, //显示和隐藏添加志愿按钮

                rules: {
                    name: [
                        {required: true, message: "真实姓名不能为空！", trigger: "blur"},
                        {
                            validator: function (rule, value, callback) {
                                if (nameCheck(value) === false) {
                                    callback(new Error("格式1-30位字符（中英文字符、数字、下划线、· 或 .）"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur",
                        },
                    ],
                    start_school_date: [
                        {
                            required: true,
                            message: "高中入学年份不能为空！",
                            trigger: "change",
                        },
                    ],
                    phone: [
                        {required: true, message: "手机号不能为空！", trigger: "blur"},
                        {validator: validator.validatePhone, trigger: "blur"},
                    ],
                    email: [
                        {required: true, message: "邮箱不能为空！", trigger: "blur"},
                        {validator: validator.validateEmail, trigger: "blur"},
                    ],
                    schoolprovince: [
                        {
                            required: true,
                            message: "请选择就读中学省份！",
                            trigger: "change",
                        },
                    ],
                    schoolcity: [
                        {
                            required: true,
                            message: "请选择就读中学城市！",
                            trigger: "change",
                        },
                    ],
                    school: [
                        {required: true, message: "请选择就读中学！", trigger: "change"},
                    ],
                },

                imageUrl: "",
                examUuid: "", //当前考试uuid
            };
        },
        created() {
            this.student_id = window.localStorage.getItem("student_id");
            this.sfzNum = window.localStorage.getItem("sfzNum");
            // this.username = window.localStorage.getItem("username");
            this.is_check_id_card = window.localStorage.getItem("is_check_id_card");
            this.applicationstatus = window.localStorage.getItem("applicationstatus");
            this.examUuid = window.localStorage.getItem("exam_uuid");
            this.getTenYear();
            this.provinceData = JSON.parse(window.localStorage.getItem("provinceData"));
            this.cityData = JSON.parse(window.localStorage.getItem("cityData"));
            if (
                this.provinceData == null ||
                !this.provinceData ||
                Object.values(this.provinceData).length == 0
            ) {
                this.getProvinceLists(); //获取全部省份
            }
            if (
                this.cityData == null ||
                !this.cityData ||
                Object.values(this.cityData).length == 0
            ) {
                this.getCityLists(); //获取全国城市
            }
            this.getSubjectsList(); //获取学科列表--选科使用
            this.getUniversityList(0); //获取所有国内大学列表
            this.getUniversityList(1); //获取所有国外大学列表
            this.getProfessionList(0, 0, 0, 0, 0);
            if (this.selfInfo.wishinfo.length >= 5) {
                this.hideAddWishBtn = true;
            }
        },
        mounted() {
            if (window.localStorage.getItem('isShare')) {
                const element = document.querySelector('#share');
                element.scrollIntoView({
                    behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
                    block: "center", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
                    inline: "nearest" // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
                })
            }
        },
        watch: {
            selfInfos(val) {
                for (let k in val) {
                    if (val[k] == null || !val[k]) {
                        val[k] = this.selfInfoOld[k];
                    }
                }
                this.selfInfo = val;
                if (val.hasOwnProperty("gender") && (val.gender + '').toString()) {
                    let gender = Number(val.gender);
                    console.log(gender, "返回数据");
                    this.selfInfo.sex = gender;
                    this.sex = this.selfInfo.sex;
                }
                // 证件号码存在,根据证件号码自动识别男女
                if (this.selfInfo.id_card && this.selfInfo.id_card !== "") {
                    if (this.selfInfo.id_card.slice(-2, -1) % 2 == 0) {
                        this.selfInfo.sex = 1;
                        this.sex = 1;
                    } else {
                        this.selfInfo.sex = 0;
                        this.sex = 0;
                    }
                }
                if (val.birthday && val.birthday !== "") {
                    //出生日期
                    this.bdYear = val.birthday.slice(0, 4); //前4位取年
                    this.bdMonth = val.birthday.slice(4, 6); //中2位取月
                    this.bdDay = val.birthday.slice(6, 8); //后2位取日
                }
                if (
                    val.jiguan_code &&
                    val.jiguan_code !== "" &&
                    val.jiguan_code !== "00"
                ) {
                    //籍贯
                    console.log(val.jiguan_code, "val.jiguan_code+++++");
                    this.jgProvinceCode = Number(val.jiguan_code.slice(0, 2)); //前2位取省份
                    console.log(this.jgProvinceCode, "val.jiguan_code+++++");
                    this.selectJgProvince(this.jgProvinceCode, "1");
                    this.jgCityCode = Number(val.jiguan_code.slice(2, 6)); //后4位取城市
                    console.log(this.jgCityCode, "val.jiguan_code+++++");
                }
                if (
                    val.shengyuandi_code &&
                    val.shengyuandi_code !== "" &&
                    val.shengyuandi_code !== "00"
                ) {
                    //生源地
                    this.sydProvinceCode = Number(val.shengyuandi_code.slice(0, 2)); //前2位取省份
                    this.selectSydProvince(this.sydProvinceCode, "1");
                    this.sydCityCode = Number(val.shengyuandi_code.slice(2, 6)); //后4位取城市
                }
                if (val.category && val.category == "0") {
                    //科类
                    this.selfInfo.category = "";
                } else if (val.category && val.category == 3) {
                    this.subjectStatus = true;
                    if (val.gk_selectsubject == "" || !val.gk_selectsubject) {
                        this.checkedSubject = [];
                    } else {
                        this.checkedSubject = val.gk_selectsubject.split("#");
                    }
                }
                if (
                    val.address_code &&
                    val.address_code !== "" &&
                    val.address_code !== "00"
                ) {
                    //家庭住址
                    this.jtProvinceCode = Number(
                        val.address_code.toString().substring(0, 2)
                    ); //前2位取省份
                    this.selectJtProvince(this.jtProvinceCode, "1");
                    this.jtCityCode = Number(val.address_code.toString().substring(2, 6)); //后4位取城市
                }
                if (
                    val.schoolprovince &&
                    val.schoolprovince !== "" &&
                    val.schoolprovince !== "00"
                ) {
                    //学校信息--省份
                    this.selfInfo.schoolprovince = Number(val.schoolprovince);
                    this.currentSchoolCityList = [];
                    let currentCity = [];
                    console.log(this.cityData, "this.cityData.length")
                    if (this.cityData == null ||
                        !this.cityData ||
                        Object.values(this.cityData).length == 0) {
                        getCityList()
                            .then((res) => {
                                if (res.data.code === 200) {
                                    this.cityData = res.data.list;
                                    for (let key in this.cityData) {
                                        if (
                                            this.cityData[key].parent_id == Number(val.schoolprovince)
                                        ) {
                                            currentCity.push(this.cityData[key]);
                                        }
                                    }
                                } else {
                                    this.$message.error(res.data.message);
                                }
                            })
                            .catch((err) => {
                                this.$message.error(err);
                            });
                    } else {
                        for (let key in this.cityData) {
                            if (this.cityData[key].parent_id == Number(val.schoolprovince)) {
                                currentCity.push(this.cityData[key]);
                            }
                        }
                    }
                    this.currentSchoolCityList = currentCity;
                }
                if (val.schoolcity && val.schoolcity !== "" && val.schoolcity !== "00") {
                    //学校信息--城市
                    this.selfInfo.schoolcity = Number(val.schoolcity);
                    this.getSchoolLists(Number(val.schoolcity), 1);
                }
                if (val.school && val.school !== "" && val.school !== "00") {
                    //学校信息--学校名称
                    this.selfInfo.school = Number(val.school);
                }
                console.log(val)
                //强基志愿回显
                if (val.qjwishinfo && val.qjwishinfo.length > 0) {
                    val.qjwishinfo.forEach((item, index) => {
                        console.log('item' + index, item)
                        if (item.zyunivid == 0) {
                            this.qjWishList[index].zyunivid = "";
                        } else {
                            this.qjWishList[index].zyunivid = item.zyunivid;
                        }
                        if (item.zy1id == 0) {
                            this.qjWishList[index].zy1id = "";
                        } else {
                            this.getProfessionList(0, item.zy1id, 0, index, 1, 2);
                            this.qjWishList[index].zy1id = item.zy1id;
                        }
                        if (item.zy2id == 0) {
                            this.qjWishList[index].zy2id = "";
                        } else {
                            this.getProfessionList(0, item.zy2id, 0, index, 1, 3);
                            this.qjWishList[index].zy2id = item.zy2id;
                        }
                        if (item.zy3id == 0) {
                            this.qjWishList[index].zy3id = "";
                        } else {
                            this.qjWishList[index].zy3id = item.zy3id;
                        }
                    });
                }
                //国内志愿回显
                if (val.wishinfo && val.wishinfo.length > 0) {
                    val.wishinfo.forEach((item, index) => {
                        if (item.zyunivid == 0) {
                            this.wishList[index].zyunivid = "";
                        } else {
                            this.wishList[index].zyunivid = item.zyunivid;
                        }
                        if (item.zy1id == 0) {
                            this.wishList[index].zy1id = "";
                        } else {
                            this.getProfessionList(0, item.zy1id, 0, index, 2, 2);
                            this.wishList[index].zy1id = item.zy1id;
                        }
                        if (item.zy2id == 0) {
                            this.wishList[index].zy2id = "";
                        } else {
                            this.getProfessionList(0, item.zy2id, 0, index, 2, 3);
                            this.wishList[index].zy2id = item.zy2id;
                        }
                        if (item.zy3id == 0) {
                            this.wishList[index].zy3id = "";
                        } else {
                            this.wishList[index].zy3id = item.zy3id;
                        }
                    });
                }
                //中外志愿回显
                if (val.coopwishinfo && val.coopwishinfo.length > 0) {
                    val.coopwishinfo.forEach((item, index) => {
                        if (item.zyunivid == 0) {
                            this.coopWishList[index].zyunivid = "";
                        } else {
                            this.getProfessionList(item.zyunivid, 0, 1, index, 3, 1);
                            this.coopWishList[index].zyunivid = item.zyunivid;
                        }
                        if (item.zy1id == 0) {
                            this.coopWishList[index].zy1id = "";
                        } else {
                            this.getProfessionList(item.zyunivid, item.zy1id, 1, index, 3, 2);
                            this.coopWishList[index].zy1id = item.zy1id;
                        }
                        if (item.zy2id == 0) {
                            this.coopWishList[index].zy2id = "";
                        } else {
                            this.coopWishList[index].zy2id = item.zy2id;
                        }
                    });
                }
            },
            deep: true,
        },
        methods: {
            onBlurValue(idx1, idx2, flag) {
                let key = "";
                if (flag === "0") {
                    key = "myscore";
                }
                if (flag === "1") {
                    key = "totalscore";
                }
                let isdot = /[\.]/.test(
                    this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key]
                );
                let lf, rt;
                if (isdot) {
                    lf = this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key].split(
                        "."
                    )[0];
                    rt = this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key].split(
                        "."
                    )[1];
                    if (rt == "" || rt == 0) {
                        this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] = lf;
                    }
                }
            },
            onInputValue(idx1, idx2, flag) {
                let key = "";
                if (flag === "0") {
                    key = "myscore";
                }
                if (flag === "1") {
                    key = "totalscore";
                }
                let subI = this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][
                    "subjectIndex"
                    ];
                if (subI == 0 || subI == 1 || subI == 2) {
                    if (this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] > 150) {
                        this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] = 150;
                        return;
                    }
                }
                if (subI == 3) {
                    if (this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] > 110) {
                        this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] = 110;
                        return;
                    }
                }
                if (subI == 4 || subI == 5 || subI == 6 || subI == 7 || subI == 8) {
                    if (this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] > 100) {
                        this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] = 100;
                        return;
                    }
                }
                if (subI == 9) {
                    if (this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] > 300) {
                        this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] = 300;
                        return;
                    }
                }
                let isdot = /[\.]/.test(
                    this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key]
                );
                let lf, rt;
                if (isdot) {
                    lf = this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key].split(
                        "."
                    )[0];
                    rt = this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key].split(
                        "."
                    )[1];
                    lf = lf.replace(/^\d{4}$/g, lf.slice(0, 3));
                    rt = rt.replace(/[^0|5]{1}$/g, "");
                    rt = rt.slice(0, 1);
                    if (lf != "") {
                        lf = parseInt(lf);
                    }

                    this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] = lf + "." + rt;
                } else {
                    lf = this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key];
                    lf = lf.replace(/^[\d.]{4}$/g, lf.slice(0, 3));
                    if (lf != "") {
                        lf = parseInt(lf);
                    }
                    this.selfInfo.scoreinfo[idx1]["scoreItems"][idx2][key] = lf;
                }
            },
            // 子组件触发，关闭提示弹框
            closeDialog(value) {
                this.dialogVisibleFu = value;
            },
            checkNum(e) {
                console.log(e, "e");
                console.log(e.data, "e");
            },
            changeGender(val) {
                console.log(val, "改变性别");
                this.selfInfo.sex = val;
                this.$forceUpdate();
            },
            //解决组件嵌套太深，导致无法组件无法刷新的问题
            onInput() {
                this.$forceUpdate();
            },
            //获取全国省份
            getProvinceLists() {
                getProvinceList()
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.provinceData = res.data.list;
                            window.localStorage.setItem(
                                "provinceData",
                                JSON.stringify(res.data.list)
                            );
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //获取全国城市
            getCityLists() {
                getCityList()
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.cityData = res.data.list;
                            window.localStorage.setItem(
                                "cityData",
                                JSON.stringify(res.data.list)
                            );
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //获取学科列表--选科使用
            getSubjectsList() {
                getSubjects()
                    .then((res) => {
                        if (res.data.code === 200) {
                            let resData = res.data.list;
                            let resArr = [];
                            Object.keys(resData).forEach((key) => {
                                let obj = {
                                    id: key,
                                    name: resData[key],
                                };
                                resArr.push(obj);
                            });
                            this.subjectsList = resArr;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //获取大学列表
            getUniversityList(data) {
                getUniversity(data)
                    .then((res) => {
                        if (res.data.code === 200) {
                            let resData = res.data.list;
                            let resArr = [];
                            Object.keys(resData).forEach((key) => {
                                let obj = {
                                    id: key,
                                    name: resData[key].name,
                                };
                                resArr.push(obj);
                            });
                            if (data == 0) {
                                this.universityList = resArr;
                            } else {
                                this.universityOutList = resArr;
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },

            //获取个人信息
            getAccountList() {
                if (this.examUuid && this.examUuid !== "") {
                    getAccount({exam_uuid: this.examUuid})
                        .then((res) => {
                            console.log(res, "res");
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    this.$message.error("获取uuid失败，请检查网络并刷新页面重试");
                }
            },
            //获取近10年的年份
            getTenYear() {
                let nowDate = new Date();
                // 明年年份（当前年份+1）
                // 当前年份
                let currentYear = nowDate.getFullYear() + 1;
                console.log(nowDate, currentYear);
                let yearArr = [];
                let rxYearArr = [];
                for (let i = 0; i < 22; i++) {
                    let year = currentYear - i;
                    yearArr.push(year);
                }
                console.log(yearArr);
                for (let i = 0; i < 12; i++) {
                    let year = currentYear + 1 - i;
                    rxYearArr.push(year);
                }
                this.yearArrs = yearArr;
                this.rxYearArrs = rxYearArr;
            },
            //返回当前省份下的城市列表
            getCurrentCityLists(val) {
                let currentCity = [];
                if (
                    this.cityData == null ||
                    !this.cityData ||
                    Object.values(this.cityData).length == 0
                ) {
                    getCityList()
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.cityData = res.data.list;
                                window.localStorage.setItem(
                                    "cityData",
                                    JSON.stringify(res.data.list)
                                );
                                for (let key in this.cityData) {
                                    if (this.cityData[key].parent_id == val) {
                                        currentCity.push(this.cityData[key]);
                                    }
                                }
                                return currentCity;
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err);
                        });
                } else {
                    for (let key in this.cityData) {
                        if (this.cityData[key].parent_id == val) {
                            currentCity.push(this.cityData[key]);
                        }
                    }
                    return currentCity;
                }
                return currentCity;
            },
            //选择籍贯省份change事件
            selectJgProvince(val, status) {
                if (status === "0") {
                    this.jgCityCode = "";
                }
                this.currentJgCityList = [];
                this.currentJgCityList = this.getCurrentCityLists(val);
                console.log(this.currentJgCityList, "this.currentJgCityList");
            },
            //选择生源地省份change事件
            selectSydProvince(val, status) {
                if (status === "0") {
                    this.sydCityCode = "";
                }
                this.currentSydCityList = [];
                this.currentSydCityList = this.getCurrentCityLists(val);
            },
            //选择学科change事件
            selectSubjectInfo(val) {
                if (val == 3) {
                    this.subjectStatus = true;
                } else {
                    this.subjectStatus = false;
                }
            },
            //选择家庭住址省份change事件
            selectJtProvince(val, status) {
                if (status === "0") {
                    this.jtCityCode = "";
                }
                this.currentJtCityList = [];
                this.currentJtCityList = this.getCurrentCityLists(val);
            },

            //选择学校省份change事件
            selectSchoolProvince(val) {
                this.currentSchoolCityList = [];
                this.currentSchoolList = []
                this.selfInfo.schoolinfo.province = this.provinceData[val].name; //赋值name
                this.currentSchoolCityList = this.getCurrentCityLists(val);
                this.selfInfo.schoolcity = ""; //清空已选择学校城市数据
                this.selfInfo.school = ""; //清空已选择学校数据
            },
            //选择学校城市change事件
            selectSchoolCity(val) {
                this.selfInfo.schoolinfo.city = this.cityData[val].name; //赋值name
                this.currentSchoolList = []
                this.getSchoolLists(val, 0);
            },
            //获取对应省市的学校列表
            getSchoolLists(id, flag) {
                getSchoolList({city_id: id})
                    .then((res) => {
                        console.log(res)
                        if (res.data.code === 200) {
                            this.currentSchoolList = res.data.list;
                            if (flag === 0) {
                                this.selfInfo.school = ""; //清空已选择学校数据
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //选择中学学校change事件
            selectSchool(val) {
                this.selfInfo.schoolinfo.name = this.currentSchoolList[val].name;
            },
            //选择大学学校
            selectUniversity(val, flag, index) {
                console.log(val);
                switch (flag) {
                    case 1: //强基计划
                        let obj = {};
                        obj = this.universityList.find(function (item) {
                            return item.id == Number(val);
                        });
                        this.selfInfo.qjwishinfo[index].zyunivid = Number(obj.id);
                        this.selfInfo.qjwishinfo[index].zyunivname = obj.name;
                        // this.selfInfo.qjwishinfo[index].zy1id = "0";
                        // this.selfInfo.qjwishinfo[index].zy1name = "";
                        // this.selfInfo.qjwishinfo[index].zy2id = "0";
                        // this.selfInfo.qjwishinfo[index].zy2name = "";
                        // this.selfInfo.qjwishinfo[index].zy3id = "0";
                        // this.selfInfo.qjwishinfo[index].zy3name = "";
                        // this.getProfessionList(0,0,0,index,flag);
                        break;
                    case 2: //国内志愿
                        let obj2 = {};
                        obj2 = this.universityList.find(function (item) {
                            return item.id == Number(val);
                        });
                        this.selfInfo.wishinfo[index].zyunivid = Number(obj2.id);
                        this.selfInfo.wishinfo[index].zyunivname = obj2.name;
                        // this.selfInfo.wishinfo[index].zy1id = "0";
                        // this.selfInfo.wishinfo[index].zy1name = "";
                        // this.selfInfo.wishinfo[index].zy2id = "0";
                        // this.selfInfo.wishinfo[index].zy2name = "";
                        // this.selfInfo.wishinfo[index].zy3id = "0";
                        // this.selfInfo.wishinfo[index].zy3name = "";
                        // this.getProfessionList(0,0,0,index,flag);
                        break;
                    case 3: //中外志愿
                        let obj3 = {};
                        obj3 = this.universityOutList.find(function (item) {
                            return item.id == Number(val);
                        });
                        this.selfInfo.coopwishinfo[index].zyunivid = Number(obj3.id);
                        this.selfInfo.coopwishinfo[index].zyunivname = obj3.name;
                        this.coopWishList[index].zy1id = "";
                        this.coopWishList[index].zy2id = "";
                        this.selfInfo.coopwishinfo[index].zy1id = "";
                        this.selfInfo.coopwishinfo[index].zy1name = "";
                        this.selfInfo.coopwishinfo[index].zy2id = "";
                        this.selfInfo.coopwishinfo[index].zy2name = "";
                        this.getProfessionList(obj3.id, 0, 1, index, flag, 1);
                        break;
                }
            },

            selectWish(val, flag, index, serial) {
                //选择专业   val:选择值；flag:1强基，2国内，3国外；index:第几志愿；serial:当前第几个专业
                switch (flag) {
                    case 1: //强基计划
                        if (serial == 1) {
                            //第一等级专业
                            let obj = {};
                            obj = this.professionList.find(function (item) {
                                return item.id === val;
                            });
                            this.selfInfo.qjwishinfo[index].zy1id = Number(obj.id);
                            this.selfInfo.qjwishinfo[index].zy1name = obj.name;
                            this.qjWishList[index].zy2id = "";
                            this.qjWishList[index].zy3id = "";
                            this.selfInfo.qjwishinfo[index].zy2id = "";
                            this.selfInfo.qjwishinfo[index].zy2name = "";
                            this.selfInfo.qjwishinfo[index].zy3id = "";
                            this.selfInfo.qjwishinfo[index].zy3name = "";
                            this.getProfessionList(0, val, 0, index, flag, 2);
                        } else if (serial == 2) {
                            //第二等级专业
                            let obj = {};
                            obj = this.qjWishList[index].qjZYListTwo.find(function (item) {
                                return item.id === val;
                            });
                            this.selfInfo.qjwishinfo[index].zy2id = Number(obj.id);
                            this.selfInfo.qjwishinfo[index].zy2name = obj.name;
                            this.qjWishList[index].zy3id = "";
                            this.selfInfo.qjwishinfo[index].zy3id = "";
                            this.selfInfo.qjwishinfo[index].zy3name = "";
                            this.getProfessionList(0, val, 0, index, flag, 3);
                        } else if (serial == 3) {
                            //第三等级专业
                            let obj = {};
                            obj = this.qjWishList[index].qjZYListThree.find(function (item) {
                                return item.id === val;
                            });
                            this.selfInfo.qjwishinfo[index].zy3id = Number(obj.id);
                            this.selfInfo.qjwishinfo[index].zy3name = obj.name;
                        } else {
                        }

                        break;
                    case 2: //国内志愿
                        if (serial == 1) {
                            //第一等级专业
                            let obj = {};
                            obj = this.professionList.find(function (item) {
                                return item.id === val;
                            });
                            this.selfInfo.wishinfo[index].zy1id = Number(obj.id);
                            this.selfInfo.wishinfo[index].zy1name = obj.name;
                            this.wishList[index].zy2id = "";
                            this.wishList[index].zy3id = "";
                            this.selfInfo.wishinfo[index].zy2id = "";
                            this.selfInfo.wishinfo[index].zy2name = "";
                            this.selfInfo.wishinfo[index].zy3id = "";
                            this.selfInfo.wishinfo[index].zy3name = "";
                            this.getProfessionList(0, val, 0, index, flag, 2);
                        } else if (serial == 2) {
                            //第二等级专业
                            let obj = {};
                            obj = this.wishList[index].wishListTwo.find(function (item) {
                                return item.id === val;
                            });
                            this.selfInfo.wishinfo[index].zy2id = Number(obj.id);
                            this.selfInfo.wishinfo[index].zy2name = obj.name;
                            this.wishList[index].zy3id = "";
                            this.selfInfo.wishinfo[index].zy3id = "";
                            this.selfInfo.wishinfo[index].zy3name = "";
                            this.getProfessionList(0, val, 0, index, flag, 3);
                        } else if (serial == 3) {
                            //第三等级专业
                            let obj = {};
                            obj = this.wishList[index].wishListThree.find(function (item) {
                                return item.id === val;
                            });
                            this.selfInfo.wishinfo[index].zy3id = Number(obj.id);
                            this.selfInfo.wishinfo[index].zy3name = obj.name;
                        } else {
                        }
                        break;
                    case 3: //中外志愿
                        if (serial == 1) {
                            //第一等级专业
                            let obj = {};
                            obj = this.coopWishList[index].coopWishListOne.find(function (item) {
                                return item.id === val;
                            });
                            this.selfInfo.coopwishinfo[index].zy1id = Number(obj.id);
                            this.selfInfo.coopwishinfo[index].zy1name = obj.name;
                            this.coopWishList[index].zy2id = "";
                            this.getProfessionList(
                                this.selfInfo.coopwishinfo[index].zyunivid,
                                val,
                                1,
                                index,
                                flag,
                                2
                            );
                        } else if (serial == 2) {
                            //第二等级专业
                            let obj = {};
                            obj = this.coopWishList[index].coopWishListTwo.find(function (item) {
                                return item.id === val;
                            });
                            this.selfInfo.coopwishinfo[index].zy2id = Number(obj.id);
                            this.selfInfo.coopwishinfo[index].zy2name = obj.name;
                        } else {
                        }
                        break;
                }
            },
            //获取大学专业列表
            getProfessionList(uuid, id, isAbroad, wishIndex, flag, serial) {
                //uuid:大学uuid,id:父级id；isAbroad：国内0，国外1；wishIndex：志愿索引；flag：1强基，2国内，3中外;serial:1第一专业，2第二专业，3第三专业；
                getProfession(uuid, id)
                    .then((res) => {
                        if (res.data.code === 200) {
                            let resData = res.data.list;
                            let resArr = [];
                            Object.keys(resData).forEach((key) => {
                                let obj = {
                                    id: resData[key].id,
                                    name: resData[key].name,
                                };
                                resArr.push(obj);
                            });
                            if (uuid == 0) {
                                if (id == 0) {
                                    this.professionList = resArr;
                                } else {
                                    switch (
                                        flag // 强基
                                        ) {
                                        case 1:
                                            if (serial == 2) {
                                                this.qjWishList[wishIndex].qjZYListTwo = resArr;
                                            } else if (serial == 3) {
                                                this.qjWishList[wishIndex].qjZYListThree = resArr;
                                            }
                                            break;
                                        case 2:
                                            if (serial == 2) {
                                                //国内志愿
                                                this.wishList[wishIndex].wishListTwo = resArr;
                                            } else if (serial == 3) {
                                                this.wishList[wishIndex].wishListThree = resArr;
                                            }
                                            break;
                                    }
                                }
                            } else {
                                if (serial == 1) {
                                    //中外志愿
                                    this.coopWishList[wishIndex].coopWishListOne = resArr;
                                } else if (serial == 2) {
                                    this.coopWishList[wishIndex].coopWishListTwo = resArr;
                                }
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //数字转小写
            smallToBig(val) {
                switch (val) {
                    case 0:
                        return "一";
                    case 1:
                        return "二";
                    case 2:
                        return "三";
                    case 3:
                        return "四";
                    case 4:
                        return "五";
                    case 5:
                        return "六";
                    case 6:
                        return "七";
                    case 7:
                        return "八";
                    default:
                        //可选
                        return "";
                }
            },
            //添加志愿--国内志愿新增至多5个
            addWish() {
                if (this.selfInfo.wishinfo.length < 5) {
                    let obj1 = {
                        zyunivid: "0",
                        zy1id: "0",
                        zy2id: "0",
                        zy3id: "0",
                        zyunivname: "",
                        zy1name: "",
                        zy2name: "",
                        zy3name: "",
                    };
                    let obj2 = {
                        zyunivid: "",
                        zy1id: "",
                        zy2id: "",
                        zy3id: "",
                        wishListTwo: [],
                        wishListThree: [],
                    };
                    this.selfInfo.wishinfo.push(obj1);
                    this.wishList.push(obj2);
                    console.log(this.selfInfo.wishinfo.length);
                    if (this.selfInfo.wishinfo.length == 5) {
                        this.hideAddWishBtn = true;
                    }
                } else {
                    this.$message.error("国内志愿最多可添加5个");
                    this.hideAddWishBtn = true;
                }
            },
            //上传证件照
            beforeAvatarUpload(file) {
                console.log(file, "返回图片信息");
                const isJPG = file.type === "image/jpeg";
                const isPNG = file.type === "image/png";
                const isLt2M = file.size / 1024 / 1024 < 2;
                //校验图片尺寸大小
                const _this = this;

                if (!(isJPG || isPNG)) {
                    this.$message.error("上传头像图片只能是 JPG / png格式!");
                    return false;
                }
                if (!isLt2M) {
                    this.$message.error("上传证件照文件大小不超过2M!");
                    return false;
                }
                return true;
            },
            //证件照上传
            uploadPicture(params) {
                console.log("已经准备好上传图片", params);
                this.file = params.file;
                let raw = params.file;
                let image = {
                    name: raw.name,
                    size: raw.size,
                    pro: 1,
                    type: raw.type,
                };
                this.getOssConfigs(image, params.file);
                this.getCosConfigs(image, params.file);
            },
            // oss上传
            async getOssConfigs(image, file) {
                // this.loading = true;
                await getAvatarConfig({image: image})
                    .then((res) => {
                        console.log(res, "开始上传图片");
                        if (res.data.code === 200) {
                            let oss_url = res.data.config.host;
                            let filename = res.data.config.file;
                            let formData = new FormData();
                            formData.append("OSSAccessKeyId", res.data.config.accessid);
                            formData.append("policy", res.data.config.policy);
                            formData.append("signature", res.data.config.signature);
                            formData.append("filename", res.data.config.file);
                            formData.append("key", res.data.config.savefile);
                            formData.append("callback", res.data.config.callback);
                            formData.append("success_action_status", 200);
                            formData.append("file", file);
                            upload(oss_url, formData)
                                .then((ress) => {
                                    // if (ress.data.code == 200) {
                                    //     console.log(ress, "上传证件照返回数据");
                                    //     this.loading = false;
                                    //     this.selfInfo.picture_path =
                                    //         filename + "?t=" + Date.parse(new Date());
                                    //     this.$forceUpdate();
                                    //     this.$message.success("上传成功");
                                    // } else {
                                    //     this.loading = false;
                                    //     this.$message.error(ress.data.message);
                                    // }
                                })
                                .catch((err) => {
                                    // this.$message.error(err);
                                });
                        } else {
                            // this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        // this.getIdCardPic();
                    });
            },
            // cos上传
            async getCosConfigs(image, file) {
                this.loading = true;
                await getAvatarConfigCos({image: image})
                    .then((res) => {
                        console.log(res, "开始上传图片");
                        let resData = res.data
                        if (resData.code === 200) {
                            const cos = new COS({
                                // 必选参数
                                getAuthorization: (options, callback) => {
                                    callback({
                                        TmpSecretId: resData.Credentials.TmpSecretId,
                                        TmpSecretKey: resData.Credentials.TmpSecretKey,
                                        XCosSecurityToken: resData.Credentials.Token,
                                        StartTime: resData.startTime, // 时间戳，单位秒，如：1580000000
                                        ExpiredTime: resData.ExpiredTime, // 时间戳，单位秒，如：1580000900
                                    });
                                },
                            });
                            cos.putObject({
                                    Bucket: resData.bucket,
                                    Region: resData.region,
                                    Key: resData.saveFile,
                                    Body: file,
                                    onProgress: (progressData) => {
                                        console.log("上传进度：" + JSON.stringify(progressData));
                                    },
                                },
                                (err, data) => {
                                    if (err) {
                                        console.log(err);
                                        this.loading = false;
                                        this.$message.error('上传失败！')
                                    } else {
                                        console.log(data);
                                        this.signUploadSuccess('http://' + data.Location);
                                    }
                                }
                            );
                        } else {
                            this.$message.error(resData.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        // this.getIdCardPic();
                    });
            },
            // cos上传-告诉后端上传成功
            async signUploadSuccess(path) {
                await getAvatarConfigCos({
                    image: path,
                    action: 'notify'
                }).then(res => {
                    if (res.data.code != 200) {
                        this.loading = false;
                        return this.$message.error('上传失败！');
                    }
                    this.loading = false;
                    this.$message.success('上传成功！');
                    this.selfInfo.picture_path = path + "?t=" + Date.parse(new Date());
                    this.$forceUpdate();
                }).catch(err => {
                    this.loading = false;
                })
            },
            //数据传输至父组件
            getFormData() {
                this.$refs["ruleForm"].validate((valid, object) => {
                    if (valid) {
                        if (this.selfInfo.category == 3 && this.checkedSubject.length != 3) {
                            let checkedSubject = {
                                checkedSubject: [
                                    {
                                        message: "请选择三种科目!",
                                        field: "checkedSubject",
                                    },
                                ],
                            };
                            return this.scrollView(checkedSubject);
                        }
                        this.selfInfo.birthday =
                            this.bdYear.toString() +
                            this.bdMonth.toString() +
                            this.bdDay.toString(); //组装出生日期
                        this.selfInfo.jiguan_code =
                            this.jgProvinceCode.toString() + this.jgCityCode.toString();
                        this.selfInfo.shengyuandi_code =
                            this.sydProvinceCode.toString() + this.sydCityCode.toString();

                        //科类为：选科时，组装选科id
                        if (this.selfInfo.category == 3) {
                            if (this.checkedSubject.length > 0) {
                                console.log(this.checkedSubject.join("#"), "========");
                                this.selfInfo.gk_selectsubject = this.checkedSubject.join("#");
                            }
                        } else {
                            this.selfInfo.gk_selectsubject = "";
                        }
                        this.selfInfo.address_code =
                            this.jtProvinceCode.toString() + this.jtCityCode.toString();
                        this.selfInfo["exam_uuid"] = this.examUuid;
                        this.$emit("getValues", this.selfInfo);
                    } else {
                        console.log(object);
                        this.scrollView(object);
                    }
                });
            },
            // 滚动到固定地方
            scrollView(object) {
                console.log(object, "object");
                for (let i in object) {
                    if (!i) {
                        continue;
                    }
                    let dom = this.$refs[i];
                    if (!dom) {
                        continue;
                    }
                    // 这里是针对遍历的情况（多个输入框），取值为数组
                    if (Object.prototype.toString.call(dom) !== "[object Object]") {
                        dom = dom[0];
                    }
                    // 第一种方法（包含动画效果）
                    dom.$el.scrollIntoView({
                        // 滚动到指定节点
                        // 值有start,center,end，nearest，当前显示在视图区域中间
                        block: "center",
                        // 值有auto、instant,smooth，缓动动画（当前是慢速的）
                        behavior: "smooth",
                    });
                    this.dialogFormFu = {
                        title: "提示",
                        message: object[i][0].message,
                    };
                    this.dialogVisibleFu = true;
                    // this.$emit("getValues", object[i][0].message, false);
                    break; // 因为我们只需要检测一项,所以就可以跳出循环了
                }
            },
        },
        destroyed() {
            window.localStorage.removeItem('isShare')

        },
        components: {
            DialogBox,
        },
    };
</script>

<style lang="scss" scoped>
    .flex {
        display: flex;
    }

    .share {
        margin-top: 20px;
        background-color: #F2F1F1;
        padding: 0 10px;

        .auth_choose {
            align-items: center;
            font-size: 13px;

            div {
                font-weight: bold;
            }

            span {
                color: red;
            }
        }

        .auth_tips {
            font-size: 12px;
            color: #7F7F7F;
            line-height: 22px;

            div {
                margin: 8px 0;
            }
        }
    }

    .must {
        color: red;
        padding-right: 5px;
    }

    .el-input,
    .el-select {
        margin-top: 10px;
    }

    .selfInfoBox {
        width: 100%;

        ::v-deep .el-input__inner {
            width: 260px;
            // margin-top: 10px;
        }

        .syd-con {
            // width: 300px;
            ::v-deep .el-input__inner {
                width: 120px;
            }
        }

        .jtzz-con {
            width: 680px;

            ::v-deep .el-input__inner {
                width: 100%;
            }
        }

        .form-room-box {
            margin-top: 10px;
            height: 20px;
            line-height: 30px;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
        }

        .form-room-handle {
            font-weight: 400;
            font-size: 12px;
            margin-top: 10px;
        }

        .form-room-item {
            width: 83%;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-top: 10px;

            .form-room-handle {
                font-weight: 400;
                font-size: 12px;
                margin-top: 10px;

                .must {
                    color: red;
                    padding-right: 5px;
                }
            }
        }

        .pictureRoom {
            flex: 2;
            display: flex;
            justify-content: space-between;

            .picture {
                width: 100%;
                text-align: center;
                margin-left: 20px;

                .upload_img_box {
                    padding: 0;
                    position: relative;
                    left: -70px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .upload_sign {
                        margin-left: -145px;
                        margin-bottom: 10px;
                        font-size: 12px;
                    }

                    .upload_img {
                        width: 137px;
                        height: 208px;
                        border: 1px solid #e1e3e6;
                    }
                }

                .upload_text_box {
                    position: relative;
                    left: 85px;
                    top: -205px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-star;

                    .upload_text_item1 {
                        padding: 0 0 30px 0;
                        font-size: 14px;
                        width: 70px;
                        color: #666;
                    }

                    .uload_text_item2 {
                        display: flex;
                        flex-direction: column;
                        margin-top: -5px;

                        div {
                            text-align: left;
                            font-size: 12px;
                            line-height: 24px;
                            color: #666;
                        }
                    }
                }

                .avatar-uploader {
                    width: 300px;
                    margin-top: 10px;
                    height: 208px;
                    display: flex;
                }
            }
        }
    }

    .familyInfo {
        width: 100%;
        margin-top: 10px;

        ::v-deep .el-input__inner {
            margin-top: 10px;
        }

        .family-item {
            margin-top: 10px;
            width: 100%;

            .item-box {
                display: flex;
                flex-wrap: wrap;

                .items {
                    margin-top: 10px;
                    margin-right: 10px;

                    .items-handle {
                        font-size: 12px;
                    }

                    .center {
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    .schoolinfo {
        width: 80%;
        margin-top: 30px;

        .handle {
            font-weight: bold;
            font-size: 16px;
        }

        .schoolName {
            margin-top: 10px;

            .handle {
                font-weight: 400;
                margin-top: 15px;
                font-size: 12px;
            }

            .box {
                display: flex;
                justify-content: space-between;

                .center {
                    margin: 0 10px;
                }
            }
        }
    }

    .schoolRecord {
        .handle {
            font-weight: bold;
            margin-top: 10px;
        }

        .recordTable {
            padding: 0px;

            table {
                width: 100%;
                margin-top: 10px;

                tr {
                    width: 100%;

                    th {
                        background: #f5f5f5;
                        padding: 10px 0;
                    }

                    td {
                        text-align: center;
                        width: 8%;
                        padding: 10px 0;

                        .gradint {
                            width: 25px;
                            border-bottom: 1px solid #000;
                        }
                    }
                }
            }
        }
    }

    .lastRecord {
        .handle {
            font-weight: bold;
            margin-top: 10px;
        }

        table {
            width: 100%;
            margin-top: 10px;

            tr {
                width: 100%;

                th {
                    background: #f5f5f5;
                    padding: 10px 0;
                }

                td {
                    text-align: center;
                    width: 8%;
                    padding: 10px 0;

                    .gradint {
                        width: 20px;
                        border-bottom: 1px solid #000;
                    }
                }
            }
        }
    }

    .wash {
        width: 90%;

        .handle {
            font-weight: bold;
        }

        .handle2 {
            font-weight: bold;
            font-size: 12px;
            margin-top: 10px;
        }

        .wash-item-box {
            width: 100%;

            .form-room-box {
                span {
                    font-size: 12px;
                }
            }

            .hand {
                margin-top: 10px;
                font-weight: bold;
                font-size: 12px;
            }

            .item-box {
                margin-top: 10px;

                .items {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        .addWishBtn {
            margin: 10px 0;
            color: #ee7602;
            cursor: pointer;
        }
    }

    ::v-deep .el-input--prefix .el-input__inner {
        width: 192px;
    }

    // ::v-deep .input21 .input.el-input__inner {
    //    margin-right: 20px;
    // }
    // ::v-deep .name.el-row input.el-input__inner{
    //   margin-bottom: -10px;
    // }
    ::v-deep .el-button {
        background: #563279;
        color: #fff;
    }

    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #563279;
    }

    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    ::v-deep .bd-con input.el-input__inner {
        width: 170px;
        margin-left: 0px;
    }

    ::v-deep .apply_input input.el-input__inner {
        width: 150px;
    }

    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #590f77;
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #590f77;
        border-color: #590f77;
    }

    ::v-deep .source.el-row {
        height: 80px !important;
    }

    ::v-deep .commBox .source.el-row {
        height: 80px !important;
    }

    ::v-deep .commBox .el-col.el-col-12 {
        height: 80px !important;
        padding-top: 0px;
        margin-top: -20px;
    }

    ::v-deep .syd-con .el-select .el-input__inner {
        width: 124px;
    }

    ::v-deep .item-box div.el-input {
        margin-top: 0px;
    }

    ::v-deep .items input.el-input__inner {
        width: 298px;
        margin-bottom: 7px;
    }

    ::v-deep .box .el-input__inner {
        width: 298px;
    }

    ::v-deep .schooladdr .el-input__inner {
        width: 914px;
    }
</style>
