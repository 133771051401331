import { request } from "./network";
import axios from "axios";

//注册身份认证
//身份认证第一步
export function checkIdCard(data) {
  return request({
    method: "post",
    url: "/web/register/idCard/checkIdCard",
    data,
  });
}

//身份认证第二步
export function getIdCard(data) {
  return request({
    method: "post",
    url: "/web/register/idCard/getIdCard",
    data,
  });
}

//身份认证第三步
export function setIdCard(data) {
  return request({
    method: "post",
    url: "/web/register/idCard/setIdCard",
    data,
  });
}

//报名身份认证
//身份认证第一步
export function signupcheckIdCard(data) {
  return request({
    method: "post",
    url: "/web/signUp/idCard/checkIdCard",
    data,
  });
}

//身份认证第二步
export function signupgetIdCard(data) {
  return request({
    method: "post",
    url: "/web/signUp/idCard/getIdCard",
    data,
  });
}

//身份认证第三步
export function signupsetIdCard(data) {
  return request({
    method: "post",
    url: "/web/signUp/idCard/setIdCard",
    data,
  });
}

export function getAvatarConfig(data) {
  return request({
    method: "post",
    url: "/web/register/getAvatarConfig",
    data,
  });
}

export function getAvatarConfigCos(data) {
  return request({
    method: "post",
    url: "/web/register/getAvatarConfigCos",
    data,
  });
}
/**
 * axios:upload 文件上传方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function upload(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
