//用户名
const userName = /^[a-zA-Z_0-9]*[a-zA-Z][a-zA-Z_0-9]*$/
// 数字
const numberReg = /^\d+$|^\d+[.]?\d+$/
// 中文
const cnReg = /^[\u4e00-\u9fa5]+$/
// 邮箱
const emailReg = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
// 手机号
const phoneReg = /^1(3|4|5|6|7|8|9)\d{9}$/

export default {
    //手机号验证
    validatePhone:function (rule, value, callback) {
        if (!phoneReg.test(value) || value.length !== 11) {
            callback(new Error('手机号格式错误！'))
        } else {
            callback()
        }
    },
    // 数字验证
    validateNumber : function (rule, value, callback) {
        if (!numberReg.test(value)) {
            callback(new Error('请输入数字'))
        } else {
            callback()
        }
    },
    // 中文验证
    validateCn : function(rule, value, callback) {
        if (!cnReg.test(value)) {
            callback(new Error("请输入中文"));
        }else {
            callback()
        }
    },
    // 邮箱验证
    validateEmail : function(rule, value, callback) {
        if (!emailReg.test(value)) {
            callback(new Error('邮箱格式错误！'))
        } else {
            callback()
        }
    },
}
